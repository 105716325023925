import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Wrapper from 'components/Wrapper';

const RegisterSuccess = () => {
	return (
		<Layout pageName="register-success">
			<Helmet>
                <title>Thanks for Registering</title>
				<link rel="canonical" href="https://www.youpos.co.uk/register-success" />
				<meta name="robots" content="noindex" />
            </Helmet>
			<div className="o-section">
				<div className="o-section__header">
					<Wrapper narrow>
						<h1>Thanks for Registering!</h1>
						<p>We'll keep you up to date with our progress.</p>
					</Wrapper>
				</div>
			</div>
		</Layout>
	);
};

export default RegisterSuccess;